// @flow
import React from 'react';
import moment from 'moment';
import { withPrefix, Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges
};

const Feed = ({ edges }: Props) => (
  <div className={styles['feed']}>
    {edges.map((edge) => (
      <div className={styles['feed__item']} key={edge.node.fields.slug}>
      <div>

        <h2 className={styles['feed__item-title']}>

          <Link className={styles['feed__item-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
          {edge.node.frontmatter.locked ? <img className={styles['feed__item-password']} src='../media/lock_thumb.svg'/> : null}
        </h2>

      </div>
        <img align="left" className= {styles["feed__item-thumb"]} src={edge.node.frontmatter.thumbnail}/>
        <p className={styles['feed__item-description']}>{edge.node.frontmatter.description}</p>
        <Link className={styles['feed__item-readmore']} to={edge.node.fields.slug}>Read more</Link>
        <div className={styles['feed__item-meta']}>
          <time className={styles['feed__item-meta-time']} dateTime={moment(edge.node.frontmatter.date).format('MMMM D, YYYY')}>
            {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
          </time>
          <span className={styles['feed__item-meta-divider']} />
          <span className={styles['feed__item-meta-category']}>
            <Link to={edge.node.fields.categorySlug} className={styles['feed__item-meta-category-link']}>{edge.node.frontmatter.category}</Link>
          </span>
        </div>
        <hr className={styles['feed__divider']}></hr>
      </div>
      
    ))}
  </div>
);

export default Feed;
